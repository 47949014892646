@import "reset";

body {
    font-family: et-book,Palatino,palatino linotype,palatino lt std,book antiqua,Georgia,serif;
    font-size: 21px;
    line-height: 2rem;
    color: #111;
    background-color: #fffff8;
}

main, header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 12.5%;
}

header {
    margin: 32px inherit;
    margin-top: 120px;

    h1 {
        font-size: 48px;
        line-height: 2;
        font-weight: normal;
        color: #050505;
    }
    #tagline {
        font-size: 27px;
        font-style: italic;
        font-weight: normal;
    }

    #social {
        margin-top: 12px;
        margin-bottom: 48px;
        a {
            text-decoration: none;
        }
    }

}

header, article {
    width: 55%;
    min-width: 300px;
}
article {
    padding-bottom: 100px;
    position: relative;
}
article h2 {
    font-weight: normal;
    font-style: italic;
}
article p.meta {
    color: rgba(0, 0, 0, 0.3);
    font-style: italic;
}

a {
    text-decoration: underline;
    color: #404040;
}
h2 a {
    text-decoration: none;
}
pre {
    display: flex;
    justify-content: center;
    line-height: 1.1;
    margin: 20px 0;
}
code {
    overflow-x: auto;
    max-width: 100%;
}
blockquote {
    background: #eee;
    padding: 15px;
    border-radius: 5px;
    margin: 30px 60px;
    line-height: 1.5;
}
.center {
    text-align: center;
}
.post-snippet {
    margin: 48px 0;
}
.source {
    text-align: right;
    font-style: italic;
}
@media (min-width: 800px) {
    h5 {
        position: absolute;
        right: -150px;
        width: 120px;
        margin-top: 20px;
    }
}
@media (max-width: 1200px) {
    blockquote {
        margin: 30px 40px;
    }
}
@media (max-width: 800px) {
    header, article {
        width: 75%;
    }
    blockquote {
        margin: 30px 20px;
    }
    h5 {
        margin: 1em 0 0 0;
        font-style: italic;
    }
}
@media (max-width: 480px) {
    header, article {
        width: 95%;
    }
    blockquote {
        margin: 30px 0;
    }
}
